import {
    plotForceGraphParachute,
    centerGraph,
} from './components/forceGraph.js'
import { plotArcGraph } from './components/arcGraph.js'
//import { plotForceGraph } from './components/forceGraph.js.old.js';
// import { arcHorizontal } from '../components/arcHorizontal.js';

plotArcGraph()
//plotForceGraphParachute(); // maybe play with not showing all links
//centerGraph();
