import * as d3 from 'd3v4'
import { data } from './mis_dat.js'
import {getSwiper} from './swiper.js'

const parser = new DOMParser();

async function getData(url) {
    const response = await fetch(process.env.API_BASE_URL ? process.env.API_BASE_URL+url : url)
    const data = await response.json()
    return data
}

function htmlToElement(html) {
    var template = document.createElement('template')
    html = html.trim() // Never return a text node of whitespace as the result
    template.innerHTML = html
    return template.content.firstChild
}

function arcGraph(
    data,
    {
        nodeId = (d) => d.id, // given d in nodes, returns a unique identifier (string)
        nodeGroup, // given d in nodes, returns an (ordinal) value for color
        nodeGroups, // an array of ordinal values representing the node groups
        nodeTitle, // given d in nodes, a title string
        nodeFill = 'currentColor', // node stroke fill (if not using a group color encoding)
        nodeStroke = '#fff', // node stroke color
        nodeStrokeWidth = 1.5, // node stroke width, in pixels
        nodeStrokeOpacity = 1, // node stroke opacity
        nodeRadius = 5, // node radius, in pixels
        nodeStrength,
        linkSource = ({ source }) => source, // given d in links, returns a node identifier string
        linkTarget = ({ target }) => target, // given d in links, returns a node identifier string
        linkStroke = '#999', // link stroke color
        linkStrokeOpacity = 0.6, // link stroke opacity
        linkStrokeWidth = 1.5, // given d in links, returns a stroke width in pixels
        linkStrokeLinecap = 'round', // link stroke linecap
        linkStrength,
        linkDistance = 30,
        forceManyBodyStrength,
        invalidation, // when this promise resolves, stop the simulation
    } = {}
) {
    var svg = d3.select('svg')
    var topList

    var isDesktop=calcIsDesktop();
    var swiper=getSwiper(updateLinkColorsAndCaption, isDesktop);

    getIsDesktop();

    var activeNode = '';
    var activeImage = 1;
    
    // const w = svg._groups[0][0].scrollWidth;
    // const h = svg._groups[0][0].scrollHeight;

    const link_nodes = getData('/api/clickable_nodes');

    const w = 600
    const h = 600

    function graph(data) {
        const nodes = data.nodes.map(({ id, group, count }) => ({
            id,
            sourceLinks: [],
            targetLinks: [],
            group,
            count,
        }))

        const nodeById = new Map(nodes.map((d) => [d.id, d]))

        const links = data.links.map(({ source, target, value }) => ({
            source: nodeById.get(source),
            target: nodeById.get(target),
            value,
        }))

        for (const link of links) {
            const { source, target, value } = link
            source.sourceLinks.push(link)
            target.targetLinks.push(link)
        }

        return { nodes, links }
    }

    const { nodes, links } = graph(data)
    const color = d3.scaleOrdinal(d3.schemeCategory10)
    const step = 20
    const margin = {
        top: (h - step * (nodes.length - 1)) / 2,
        right: 20,
        bottom: (h - step * (nodes.length - 1)) / 2,
        left: w / 2,
    }
    const height = (nodes.length - 1) * step + margin.top + margin.bottom
    const y = d3
        .scalePoint()
        .domain(nodes.map((d) => d.id))
        .range([margin.top, height - margin.bottom])

    var imgIndex = 0

    function arc(d) {
        const y1 = d.source.y
        const y2 = d.target.y
        const r = Math.abs(y2 - y1) / 2
        return `M${margin.left},${y1}A${r},${r} 0,0,${y1 < y2 ? 1 : 0} ${
            margin.left
        },${y2}`
    }

    function arcShallow(d) {
        const y1 = d.source.y
        const y2 = d.target.y
        const r = Math.abs(y2 - y1) / 2
        return `M${margin.left},${y1}A${r},${r} 0,0,${y1 < y2 ? 1 : 0} ${
            margin.left
        },${y2}`
    }

    function getIsDesktop() {
        const tmp = calcIsDesktop();
        if (tmp!=isDesktop) {
            hideImage();
            isDesktop = calcIsDesktop();
        }        
    }

    function calcIsDesktop() {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        // Compare the width and height to determine the aspect ratio
        if (viewportHeight > viewportWidth) {
            return false            
        } else {
            return true
        }
    }

    function moveNav(nav) {
        if (!isDesktop) {

            d3.selectAll('.swiper').style('margin-left',`0px`);
            d3.selectAll('.swiper').classed('center_fixed', false);
            d3.selectAll('.swiper').classed('bottom_fixed', true);
            svg.style('transform', `translateY(calc(-1 * ((100vh / 2 ) - ${(svg.node().getBoundingClientRect().width)/2}px ))) rotate(90deg)`);
            svg.style('transform', `translateY(calc(-1 * ((100dvh / 2 ) - ${(svg.node().getBoundingClientRect().width)/2}px ))) rotate(90deg)`);
            
        } else {
            d3.selectAll('.swiper').style('margin-left',`${(svg.node().getBoundingClientRect().width)/2}px`);
            d3.selectAll('.swiper').style('max-width',`calc(100vw - ${(svg.node().getBoundingClientRect().width)/2}px)`);
            d3.selectAll('.swiper').classed('center_fixed', true);
            d3.selectAll('.swiper').classed('bottom_fixed', false);
            // The viewport is as wide as it is tall or wider
            svg.style('transform', `translateX(calc(-1 * ((100vw / 2 ) - ${(svg.node().getBoundingClientRect().height*0.8)/2}px )))`);
        }
    }

    function showImage(node) {
        topList = [];

        activeNode = node._groups[0][0].__data__.id;

        swiper.removeAllSlides();

        topList.push(node._groups[0][0].__data__.id)

        node._groups[0][0].__data__.sourceLinks.map((a) =>
            topList.push(a.target.id)
        )
        node._groups[0][0].__data__.targetLinks.map((a) =>
            topList.push(a.source.id)
        )

        topList = topList.concat(
            nodes.filter((a) => !topList.includes(a.id)).map((a) => a.id)
        )

        resetIcon.classed('hidden', false);
        
        console.log(svgimg.style.opacity);
        if (svgimg.style.opacity != 0.1) {
            svgimg.classList.add("hidden");
        }
        
        
        update(topList, true);

        moveNav(svg);
        // svg.transition()
        //         .duration(700)
        //         .style('transform', 'translate(0px, calc(-1 * 100vh / 2 )) rotate(90deg)');

        setTimeout(() => {
            getData(
                '/api/n_images/' +
                    activeNode 
            ).then((data) => {
                //svg.selectAll('text').transition().duration(700).attr('transform',"rotate(-35)")
                // svg.selectAll('text').transition().duration(700).attr('rotate',"-90")
                // svg.selectAll('text').style('letter-spacing','5px');

                //svg.selectAll('rect.pointerBox').transition().duration(700).attr('transform',"rotate(-35)");

                // Array.from(new Array(data.n)).map( (it,i) => {
                //     document.getElementById("andrewsSlider").appendChild(parser.parseFromString(
                //         `<div class="swiper-slide"><img src="/api/image/${node._groups[0][0].__data__.id}/${i}" loading="lazy" /><div class="swiper-lazy-preloader"></div></div>`
                //     , 'text/xml').documentElement);

                // });


                // Array.from(new Array(data.n)).map( (it,i) => {
                //     document.getElementById("andrewsSlider").appendChild(parser.parseFromString(
                //         `<div style="margin-right:0px; width:375px;" class="swiper-slide" data-swiper-slide-index="${i+9}"><img src="/api/image/Bellyman/1" loading="lazy" /><div style="margin-right:0px" class="swiper-lazy-preloader"></div></div>`
                //     , 'text/xml').documentElement);

                // });
                Array.from(new Array(data.n)).map( (it,i) => {
                    swiper.appendSlide(`<div class="swiper-slide" data-swiper-slide-index="${i}"><img src="${process.env.API_BASE_URL ? process.env.API_BASE_URL : ''}/api/image/${activeNode}/${i}" loading="lazy" /><div class="swiper-lazy-preloader"></div></div>`);
                });
                d3.selectAll('.swiper-wrapper').style('opacity',1);
                
                swiperControls.classed('hidden', false);
                swiperCaption.classed('hidden', false);

                moveBellyMan();
                svgimg.classList.remove("hidden");

                swiper.update();

                // document.getElementById('gallery_img').setAttribute('src', data.imgDat)
                //document.getElementById('gallery_img').style.opacity = 1
            })
        }, 1200);

        
    }

    function destroyAndRecreate() {
        try {
            swiper.destroy();
        } catch {
            // not created yet
        }
        swiper = getSwiper(updateLinkColorsAndCaption, isDesktop);
    }

    function hideImage(destroySwiper=false) {
        activeNode = '';
        update(topList, false);

        svgimg.classList.add("hidden");

        d3.selectAll('.swiper-wrapper').style('opacity',0);
        swiperControls.classed('hidden', true);
        swiperCaption.classed('hidden', true);

        imageNodes.innerHTML = ''
        swiperCaption.innerHTML = ''

        // setTimeout(swiper.removeAllSlides(), 500);

        setTimeout(()=>{
            resetIcon.classed('hidden', true);

            svg.style('transform', 'translateY(0) rotate(0deg)');

            // svg.transition()
            //     .duration(700)
            //     .style('transform', 'translate(0, 0) rotate(0)');
            // svg.selectAll('text').attr('transform', 'rotate(0)');
            // //svg.selectAll('text').style('letter-spacing','0px');
            // svg.selectAll('rect.pointerBox')
            //     .transition()
            //     .duration(700)
            //     .attr('transform', 'rotate(0)');

            returnBellyMan();
            svgimg.classList.remove("hidden");

            svg.classed('clicked', false);
            svg.classed('hover', false);
            label.classed('primary', false);
            label.classed('secondary', false);
            path.classed('primary', false).order();

            swiper.removeAllSlides();
            swiper.update();

            path.classed('activeImage', false);

            label
            .selectAll('text')
            .classed('activeImage', false);

            if (destroySwiper) {
                destroyAndRecreate();
            }

        },
        500);
        
    }

    window.addEventListener('resize', ()=>getIsDesktop());

    svg.append('style').text(`

        path {
            opacity: 0.4;
        }

        .hover path {
          stroke: #ccc;
          opacity: 0.4;
        }

        .hover text {
          fill: #ccc;
        }

        .hover g.primary text {
          fill: black;
          font-weight: bold;
        }

        .hover g.secondary text {
          fill: #333;
        }

        .hover path.primary {
          stroke: #333;
          stroke-opacity: 0.6;
        }


        text.activeImage {
          fill:red !important;
        }

        path.activeImage {
          stroke: #f00 !important;
          opacity: 1 !important;
        }

        .clicked path {
          stroke: #ccc;
          opacity: 0.2;
        }

        .clicked text {
          fill: #ccc;
        }

        .clicked g.primary text {
          fill: black;
          font-weight: bold;
        }

        .clicked g.secondary text {
          fill: #333;
        }

        .clicked path.primary {
          stroke: #333;
          stroke-opacity: 0.4;
        }

    `)

    const resetIcon = d3.select('#reset_screen')

    const swiperControls = d3.select('#control_panel');
    const swiperCaption = d3.select('#control_panel_caption');
    const captionText = document.getElementById('caption');
    const imageNodes = document.getElementById('imageNodes');

    resetIcon.on('click', hideImage)

    const label = svg
        .append('g')
        .attr('font-size', 14)
        .attr('text-anchor', 'end')
        .selectAll('g')
        .data(nodes)
        .enter()
        .append('g')
        .attr('transform', (d) => {
            return `translate(${margin.left},${(d.y = y(d.id))})`
        })
        .call((g) =>
            g
                .append('text')
                .attr('x', -12)
                .attr('transform-origin', '-10px 0px')
                .attr('dy', '0.35em')
                // .attr('fill', (d) => d3.lab(color(d.group)).darker(2))
                .text((d) => d.id.toLowerCase())
        )
        .call((g) =>
            g
                .append('circle')
                .attr('r', (d) => Math.pow(d.count, 1 / 3.4))
                // .attr('fill', (d) => d3.lab(color(d.group)).darker(2))
                //.attr('fill', (d) => color(d.group))
        )

    const pathGroup = svg.insert('g', '*').attr('fill', 'none')
    const path = pathGroup
        .selectAll('path')
        .data(links)
        .enter()
        .append('path')
        .attr('stroke', (d) =>
            d.source.group === d.target.group ? color(d.source.group) : '#aaa'
        )
        // .attr('stroke-opacity', 0.4)
        .attr('stroke-width', (d) => Math.pow(d.value, 1 / 4))
        .attr('d', arc)

    const overlay = svg
        .append('g')
        .attr('fill', 'none')
        .attr('pointer-events', 'all')
        .selectAll('rect')
        .data(nodes)
        .enter()
        .append('g')
        .attr('transform', (d) => {
            return `translate(0,${(d.y = y(d.id))})`
        })

    const overlay_rects = overlay
        .append('rect')
        .attr('x', -12)
        .attr('y', -step / 2)
        .attr('class', 'pointerBox')
        .attr('width', margin.left + 40)
        .attr('height', step)
        .attr('transform-origin', `${margin.left}px ${step}px`)
        .on('mouseover', function (d) {
            if (!svg.attr('class').includes('clicked')) {
                svg.classed('hover', true)
                label.classed('primary', (n) => n === d)
                label.classed(
                    'secondary',
                    (n) =>
                        n.sourceLinks.some((l) => l.target === d) ||
                        n.targetLinks.some((l) => l.source === d)
                )
                path.classed('primary', (l) => l.source === d || l.target === d)
                    .filter('.primary')
                    .raise()
            }
        })
        .on('mouseout', function (d) {
            if (!svg.attr('class').includes('clicked')) {
                svg.classed('hover', false)
                label.classed('primary', false)
                label.classed('secondary', false)
                path.classed('primary', false).order()
            }
        })

    var svgimg = document.createElementNS('http://www.w3.org/2000/svg', 'image')
    svgimg.setAttribute('height', '100')
    svgimg.setAttribute('width', '100')
    svgimg.setAttribute('id', 'bellyman')
    svgimg.setAttributeNS(
        'http://www.w3.org/1999/xlink',
        'href',
        process.env.API_BASE_URL ? process.env.API_BASE_URL+'/static/images/bellymanicon.png' : '/static/images/bellymanicon.png'
    )
    svgimg.setAttribute('x', 0.6 * w)
    svgimg.setAttribute('y', 0.5 * h - 60)
    svgimg.setAttribute('opacity', 0.4)

    function moveBellyMan() {
        svgimg.style.opacity = 0.0;
        // svgimg.setAttribute('x', 0.25 * w);
        // svgimg.setAttribute('y', 0.5 * h - 60);
        // // svgimg..attr('transform', 'rotate(90deg)');
        // svgimg.style.transformOrigin = 'calc(25% + 50px) calc(50% - 60px + 50px)';
        // svgimg.style.transform = 'rotate(-90deg)';
    }

    function returnBellyMan() {
        svgimg.style.opacity = null;
        svgimg.setAttribute('x', 0.6 * w)
        svgimg.setAttribute('y', 0.5 * h - 60)
        // svgimg..attr('transform', 'rotate(90deg)');
        svgimg.style.transformOrigin = null;
        svgimg.style.transform = null;
    }

    pathGroup
        .selectAll('path')
        .filter(`:nth-child(${Math.floor(links.length / 2)})`)
        .each(function () {
            var t = document.createElement('text')
            this.parentNode.insertBefore(svgimg, this.nextSibling)
        })

    link_nodes.then((data) => {
        label
            .selectAll('text')
            .filter((d) => data.dat.includes(d.id))
            // .style('font-weight', 'bold')
            .style('font-size',14)
        overlay
            .selectAll('.pointerBox')
            .filter((d) => data.dat.includes(d.id))
            .style('cursor', 'pointer')
        overlay_rects
            .filter((d) => data.dat.includes(d.id))
            .on('click', function (d) {
                if (!svg.attr('class').includes('clicked')) {
                    svg.classed('clicked', true)
                    label.classed('primary', (n) => n === d)
                    label.classed(
                        'secondary',
                        (n) =>
                            n.sourceLinks.some((l) => l.target === d) ||
                            n.targetLinks.some((l) => l.source === d)
                    )
                    path.classed(
                        'primary',
                        (l) => l.source === d || l.target === d
                    )
                        .filter('.primary')
                        .raise()
                    showImage(d3.select(this))
                } else {
                    if (svg.attr('class').includes('clicked')) {
                        label.classed('primary', false)
                        label.classed('secondary', false)
                        label.classed('primary', (n) => n === d)
                        label.classed(
                            'secondary',
                            (n) =>
                                n.sourceLinks.some((l) => l.target === d) ||
                                n.targetLinks.some((l) => l.source === d)
                        )
                        path.classed(
                            'primary',
                            (l) => l.source === d || l.target === d
                        )
                            .filter('.primary')
                            .raise()
                        showImage(d3.select(this))
                    } else {
                        svg.classed('clicked', false)
                        label.classed('primary', false)
                        label.classed('secondary', false)
                        path.classed('primary', false).order()
                        hideImage()
                    }
                }
            })
    })

    function update(ids, shallow) {
        y.domain(ids)
        //y.domain(nodes.sort((a, b) => d3.ascending(a.id, b.id)).map(d => d.id));

        const t = svg.transition().duration(750)

        label
            .transition(t)
            .delay((d, i) => i * 20)
            .attrTween('transform', (d) => {
                const i = d3.interpolateNumber(d.y, y(d.id))
                return (t) => `translate(${margin.left},${(d.y = i(t))})`
            })

        path.transition(t)
            .duration(750 + nodes.length * 20)
            .attrTween('d', (d) => () => shallow ? arcShallow(d) : arc(d))

        overlay
            .transition(t)
            .delay((d, i) => i * 20)
            .attrTween('transform', (d) => {
                const i = d3.interpolateNumber(d.y, y(d.id))
                return (t) => `translate(0,${(d.y = i(t))})`
            })
    }

    function updateLinkColorsAndCaption(dat) {
        
        if ((activeNode!=='') && (!Number.isNaN(dat.realIndex))) {
            
            if (dat.realIndex!=activeImage) {

                activeImage = dat.realIndex;
                
                getData(
                    '/api/image_edges/' + activeNode + '/' + dat.realIndex
                ).then((data) => {
                    
                    //path.classed('primary', false).order();

                    console.log(data);

                    path
                    .classed('activeImage', false)
                    .filter((d) => d.source.id===activeNode || d.target.id===activeNode )
                    .classed(
                        'activeImage',
                        (d) => data.target_nodes.includes(d.source.id) || data.target_nodes.includes(d.target.id)
                    );

                    label
                    .selectAll('text')
                    .classed('activeImage', false)
                    .filter((d) => data.target_nodes.includes(d.id) || d.id===activeNode)
                    .classed('activeImage', true);

                    captionText.innerHTML = data.caption;

                    imageNodes.innerHTML = `<div>${data.source_node}</div>` + data.target_nodes.map(d=> `<div>${d}</div>`).join('')
                    
                });
            }
            
        }
    }

    return Object.assign(svg.node())
}

function plotArcGraph() {
    const colors = d3.schemeCategory10 // an array of color strings, for the node groups
    const color = d3.scaleOrdinal(colors)

    const w = 800
    const h = 800

    getData('/api/arc_gallery_data').then((data) => {
        let force = arcGraph(data, {
            h: h,
            w: w,
            //colors:d3.interpolateViridis,
            nodeId: (d) => d.id,
            forceManyBodyStrength: (d) => d.charge, //d => -10*(d.count),
            nodeGroup: (d) => d.group,
            nodeRadius: 2, //d => Math.sqrt(d.count),
            //nodeStrength: l => Math.random(),
            linkStrength: (l) => -l.strength, //l => Math.pow(10,-2)*(Math.random()-0.5),
            nodeTitle: (d) => `${d.id}`,
            linkStrokeWidth: (l) =>
                l.strength ? 3 : Math.pow(10, -0.3) * Math.sqrt(l.value),

            linkStrokeOpacity: (l) => (l.strength ? 0.7 : 0.1),
            nodeStrokeWidth: 3,
            forceXPos: (d) => d.xpos * w,
            forceYPos: (d) => d.ypos * h,
            forceXStrength: (d) => d.xstren, //d.xpos*w,
            forceYStrength: (d) => d.ystren,
            nodeFill: '#fff', //d => color(d.group),
            nodeStroke: (d) => (d.id !== 'photo' ? color(d.group) : ''),
        })
    })
}

export { plotArcGraph }

// idea
// when a node is clicked, it goes to the top along with its connected nodes, making those rendered on the top.
// each image that is loaded will come with its connections (one being the triggering node which stays on top). the other connected nodes will reposition to the top near the first and the image
// will be drawn under the links for those
