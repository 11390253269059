import Swiper from 'swiper';
import { Navigation, Pagination, Manipulation, Keyboard, FreeMode } from 'swiper/modules';

import "swiper/swiper-bundle.min.css";

Swiper.use([ Manipulation ]);

const parser = new DOMParser();

document.documentElement.style.setProperty("--swiper-theme-color", "#f3f0e2")
document.documentElement.style.setProperty("--swiper-navigation-size", "0")

function htmlToElement(html) {
    var template = document.createElement('template')
    html = html.trim() // Never return a text node of whitespace as the result
    template.innerHTML = html
    return template.content.firstChild
}

// init Swiper:
// const swiper = new SwiperCore('.swiper', {
//   // Optional parameters
//   //effect:'fade',
//   centeredSlides:true,
//   slidesPerView:1,
//   // fadeEffect:{
//   //   crossFade:true
//   // },
//   keyboard: {
//     enabled: true,
//     onlyInViewport: false,
//   },
//   direction: 'horizontal',
//   loop: true,
//   createElements:true,

//   // If we need pagination
//   pagination: {
//     el: '.swiper-pagination',
//     type: 'fraction',
//     dynamicBullets:true
//   },

//   // Navigation arrows
//   navigation: {
//     nextEl: '.swiper-button-next',
//     prevEl: '.swiper-button-prev',
//   },
  
//   modules: [Navigation, Pagination, Keyboard]

// });
function getSwiper(updateLinkColorsAndCaption, isDesktop=true){
  
  var params = {
    // Optional parameters
    //effect:'fade',
    modules: isDesktop ? [Navigation, Keyboard, FreeMode] : [FreeMode],
    spaceBetween:16,
    slidesPerView: 'auto',
    centeredSlides:true,
    // navigation: {
    //     nextEl: ".swiper-button-next",
    //     prevEl: ".swiper-button-prev",
    //   },
    breakpoints: {
      '@0.00': {
        
        // spaceBetween: 10,
        freeMode: {
          enabled: false
        }
      },
      '@1.00': {
        centeredSlides:false,
        slidesPerView: 'auto',
        // spaceBetween: 10,
        freeMode: {
          enabled: true,
          sticky: true,
        }
      }
    },
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    direction: 'horizontal',
    // loop: true,
    // createElements:true,
    
  }

  if (isDesktop){
    params['navigation'] = {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      };

    document.getElementsByClassName('swiper-button-prev')[0].style.pointerEvents='unset';
    document.getElementsByClassName('swiper-button-next')[0].style.pointerEvents='unset';
  } else {
    document.getElementsByClassName('swiper-button-prev')[0].style.pointerEvents='none';
    document.getElementsByClassName('swiper-button-next')[0].style.pointerEvents='none';
  }

  const swiper = new Swiper('.swiper', {
    ...params,
  });

  swiper.on('update', updateLinkColorsAndCaption);
  swiper.on('slideChange', updateLinkColorsAndCaption);
  
  return swiper
}





const buildSwiper = (srcs) => {

  Array.from(new Array(data.n)).map( (it,i) => {
      document.getElementById("andrewsSlider").appendChild(parser.parseFromString(
          `<div style="margin-right:0px; width:375px;" class="swiper-slide" data-swiper-slide-index="${i+9}"><img src="http://localhost:5000/api/image/Bellyman/1" loading="lazy" /><div style="margin-right:0px" class="swiper-lazy-preloader"></div></div>`
      , 'text/xml').documentElement);

  });

}

export {getSwiper};